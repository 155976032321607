.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    max-width: 800px;
    margin: 50px auto;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

h1 {
    color: #333;
    margin-top: 20px;
}

p {
    color: #555;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
}

img {
    border-radius: 10px;
}
